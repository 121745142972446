<template>
  <div class="render">
    <div class="top">
      <h1>Обзвоны</h1>
      <div class="add" @click="show_dialog=true" v-if="countActiveCalls <= 80">
        <Icon name="plus" />
        Добавить обзвон
      </div>
    </div>

    <div v-if="data_copy.length || data.length" class="target_row">
      <div class="target_block">Расход: <b>{{target_rate}} р.</b></div>
      <div class="target_block">Целей: <b>{{target_count}}</b></div>
      <div class="target_block">Средняя цена цели: <b>{{target_price}} р.</b></div>
    </div>


    <div style="position: relative" v-show="hide_message" v-if="countActiveCalls > 80">
      <div
        style="height: 300px; width: 600px; position: absolute; background: #f0f0f0; left: 37%; opacity: 90%; border: 3px solid red; color: black; border-radius: 15px">
        <div style="display: flex; width: 100%; justify-content: right;">
          <button
            style="color: black; font-size: 16px; background: #c3c3c3; width: 32px; height: 32px; border-radius: 50%; border: 2px solid gray; margin-right: 10px; margin-top: 5px; font-weight: 800"
            @click="hide_message = false">X</button>
        </div>
        <div>
          <h2>Предупреждение</h2>
        </div>
        <br>
        <br>
        <div>
          <p>К сожалению, в настоящий момент вы не можете запустить обзвон.</p>
          <p> Пожалуйста обратитесь в поддержку:</p>
          <p>
            <a href="tel:+74950320009">+7 (495) 032-00-09</a>
          </p>
          <br>
        </div>
      </div>
    </div>

    <div class="content_table" v-if="isload">
      <FilterCustom v-if="data.length || data_copy.length" @updateFilter="filtering" :visible="{date:true}" />
      <Table  @stopdelete="stopdeleteEmit" @play="playCall" :page_type="'calls'" @analytics="goToAnalytics" @edit="editCall" @view="paramsView"
        @clearTarget="clearTarget" @exportUnCalled="exportUnCalled" v-if="data_copy.length || data.length" :callback_view="paramsView" :callback_play="playCall" :callback_edit="editCall"
        :callback_delete="deleteCall" :callback_stop="stopCall" :items="action_items" :callback_refresh="renderCalls" :data="data" :headers="headers" :loading="loading_ivr" />
      <div v-else class="empty">
        <Icon name="add_leads" />
        <h2 class="subtitle">
          Запустите свой первый обзвон и получите лиды уже сегодня!
        </h2>
        <div class="add" @click="show_dialog=true">
          <Icon name="plus" />
          Добавить первый обзвон
        </div>
      </div>
    </div>
    <CreateCall :guid="call_guid" :data="call_data" :show_dialog="show_dialog" :callback_refresh="renderCalls" />
    <Error :callback_refresh="renderCalls" :show_dialog="show_dialog_error" :title="show_dialog_error_message" :btn="{text: show_dialog_error_btn}" />
    <ParamCalls :guid="call_guid" :callback_refresh="renderCalls" :name="call_data" :show_dialog="show_dialog_params" :call="call" />

  </div>
</template>
<script>
import Table from '../components/Table/DataTable.vue'
import CreateCall from '../components/Modal/Calls/CreateCall.vue'
import ParamCalls from '../components/Modal/Calls/ParamsCall.vue'
import Error from '../components/Modal/ErrorModal.vue'
import Icon from '../components/SetIcon.vue'
import FilterCustom from '../components/FilterMy.vue'
export default {
  components: {
    Table, CreateCall, Error, Icon, ParamCalls, FilterCustom
  },
  beforeMount(){
    this.renderCalls()
  },
  mounted(){
    setInterval(() => {
      this.getCountActiveCalls()  
    }, 1000)
    
  },
  methods: {
    exportUnCalled(item) {
  if (item) {
    this.loading_ivr = true
    let guid = item.guid
    let base = item.select_base
    alert('Отчет начал формироваться')
    this.$http.download("/call/exportUnCalled", { id: guid, base })
      .then(() => {
        alert('Файл загружен')
        this.loading_ivr = false
      })
      .catch(error => {
        console.error('Ошибка при загрузке файла:', error)
        this.loading_ivr = false
      });
  }
},

    getCountActiveCalls(){
      this.$http.post("/system/get_count_calls_user", {}, (res) => {
        this.countActiveCalls = res.count
      })  
    },

    goToAnalytics(){
      this.$router.push('/analytics')
    },
    clearTarget(item){
      this.$http.post("/call/cleartarget", { id: item.guid },() => {
        this.renderCalls()
      })
    },
    filtering(obj){
      let filtered = []
      if(!this.data_copy.length) this.data_copy = JSON.parse(JSON.stringify(this.data))

      let startDate = new Date(obj.date[0]+" 00:00:00")
      let finishDate = new Date(obj.date[1]+" 23:59:59")

      if(obj.date && obj.date.length>0){

        this.data_copy.forEach(item => {
          let date_call = this.getDate(item.created_at)
          if(startDate.getTime() <= date_call.getTime() &&  finishDate.getTime() >= date_call.getTime()) filtered.push(item)
        })
      }
      else
      {
        filtered=this.data_copy
      }

      this.data = filtered
    },
    getDate(date){
      let cutDate = JSON.parse(JSON.stringify(date.slice(0, 10)))
      let day = Number(cutDate.slice(0, 2))
      let month = Number(cutDate.slice(3, 5)) - 1
      let year = Number(cutDate.slice(6, 10))

      let newDate = new Date(year, month, day)
      return newDate
    },
    paramsView(item){
      console.log(item)
      let {guid, name} = item
      this.call_guid = guid
      this.call_data = name
      this.show_dialog_params = true
    },
    playCall(item){
      let guid = item.guid
      this.$http.post("/call/play", { id: guid },(data) => {
        if (data.errored) {
          this.show_dialog_error = true
          this.show_dialog_error_message = data.error_message
          this.show_dialog_error_btn = "Пополнить"
          if (data.error_message == "Вам необходимо активировать аккаунт") {
            this.show_dialog_error_btn = "Активировать"
          }
        } else {
          this.renderCalls()
        }
      })
    },
    editCall(item) {
      this.call_guid = item.guid
      this.call_data = item
      this.show_dialog = true
    },
    stopdeleteEmit(item){
      if (item.status=="wait")
      {
        this.stopCall(item.guid)
      }else{
        this.deleteCall(item.guid)
      }
    },
    stopCall(guid) {
      this.$http.post("/call/stop", { id: guid },() => {
        this.renderCalls()
      })
    },
    deleteCall(guid){
      this.$http.post("/call/delete", { id: guid },() => {
        this.renderCalls()
      })
    },
    renderCalls(rerender = true, errored = false,error_message=''){
      this.show_dialog = false
      let _this = this

      setTimeout(function(){
        _this.show_dialog_error = errored
        _this.show_dialog_error_message=error_message
        _this.show_dialog_error_btn="Пополнить"
        if (error_message=="Вам необходимо активировать аккаунт") {
          _this.show_dialog_error_btn="Активировать"
        }
        _this.call_guid = false
        _this.show_dialog_params = false
        _this.call_data = false
      }, 500)

      if (rerender){
        this.$http.post("/call/list", {}, data => {
          this.isload = true
          this.data = data
          this.target_rate=0
          this.target_count=0
          this.target_price=0
          data.forEach((item)=>{
            if (item.target_on)
            {
            this.target_rate+=item.target_rate
            this.target_count+=item.target
            }
          })
          this.target_rate=this.target_rate.toFixed(2)
          if (this.target_count>0)
          {
            this.target_price=(this.target_rate/this.target_count).toFixed(2)
          }
        })
      }
    }
  },
  watch:{
    countActiveCalls: function(newVal){
      if(newVal >= 80){
        const intEdit = this.action_items.findIndex(item => item.action == "edit")
        this.action_items.splice(intEdit, 1)
        const intPlay = this.action_items.findIndex(item => item.action == "play")
        this.action_items.splice(intPlay, 1)
      }
    }
  },
    
    
  
  data(){
    return {
      loading_ivr: false,
      hide_message: true,
      countActiveCalls: 0,
      target_rate:0,
      target_count:0,
      target_price:0,
      filters_component:false,
      action_items: [
        {title: 'Параметры', action:'view'},
        {title: 'Редактировать', action:'edit'},
        {title: 'Аналитика', action:'analytics'},
        {title: 'Экспорт непрозвоненных', action:'exportUnCalled'},
        {title: 'Запустить', action:'play'},
        {title: 'Удалить/Остановить', action:'stopdelete'},
      ],
      call: {
        name:'«Тестовый обзвон 243454»',
        status: 'Обзвон закончен',
        nums: 245,
        calls_success: 152,
        percent: 62,
        time: 200,
        cost:290,
        scenarium: 'Тестовый сценарий обзвона',
        group: 'Тестовый база контактов'
      },
      call_data: false,
      show_dialog: false,
      show_dialog_params: false,
      show_dialog_error: false,
      show_dialog_error_message: "",
      show_dialog_error_btn: "",
      isload: false,
      call_guid: false,
      data: [],
      data_copy: [],
      calls: false,
      headers: [
        { text: '№', align: 'start', value: 'id' },
        { text: 'Название', value: 'callname' },
        { text: 'Статус', value: 'status' },
        { text: 'Контакты', value: 'nums' },
        { text: 'Очередь', value: 'queue' },
        { text: 'Цели', value: 'target_limit' },
        { text: 'Цена за цель', value: 'target_price' },
        { text: 'Дата начала', value: 'created_at'},
        { text: 'Действия', value: 'actions', sortable: false }
      ],
    }
  },
}
</script>
<style scoped>
.target_block{
  display: inline-block;
  margin-right: 15px;
}
.target_row{
width: 100%;
text-align:left;
padding-bottom: 10px;
}
.add{
  border: none;
  padding: 12px 20px 12px 22px;
  background: #FEE600!important;
  border-radius: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #2B2D33;
  cursor: pointer;
  display: flex;
  margin-top: 24px;
  justify-content: center;
}
.add .icon{
  margin-right: 11px;
}
.top{
  margin-bottom: 24px;
}
.subtitle{
  margin-top: 16px;
  margin-bottom: 32px;
  max-width: 450px;
}
.empty{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 500px;
}
.content_table{
  background: #fff;
  border-radius: 8px;
}
.top{
  display: flex;
  justify-content: space-between;
}
@media (max-width: 650px){
  .top{
    flex-direction: column;
  }
  .add{
    margin-top: 20px;
  }
}
@media (max-width: 400px){
  h1{
    margin-top: 15px;
  }
}
</style>
