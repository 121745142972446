<template>
	<div class="wraper">
		<h3>Подключеные интеграции АМО</h3>
		<v-overflow-btn
				class="my-3"
				:items="integrationListAMO"
				label="Вид входящего вебхука"
				height="50"
				@input="getAmoStatusesList()"
				item-value="id"
				item-text="name"
				v-model="data_local.amocrm.id_integartion"
				hide-details
		></v-overflow-btn>
		<h3>Выберите тип действия</h3>
		<v-overflow-btn
				class="my-3"
				@input="clearAmocrmFields"
				:items="arr_type"
				label="Тип действия"
				height="50"
				item-value="type"
				item-text="name"
				v-model="data_local.amocrm.type_event"
				hide-details
		></v-overflow-btn>

		<!-- Существующая логика -->
		<div v-if="data_local.amocrm.type_event === arr_type[0].type">
			<div class="subtitle mt-3 mb-2">Параметры</div>

			<div class="block_input_param">
				<v-text-field
						class="block_input_param__options"
						:readonly="true"
						hide-details
						height="40"
						value="Название карточки"
						@input="input"
						placeholder="option"
				></v-text-field>
				<div class="block_input_param__value">
					<v-text-field
							:readonly="false"
							class="ml-5"
							hide-details
							height="40"
							v-model="data_local.amocrm.fields.name"
							@input="input"
							placeholder="value"
					></v-text-field>
				</div>
			</div>

			<div class="block_input_param">
				<v-text-field
						class="block_input_param__options"
						:readonly="true"
						hide-details
						height="40"
						value="Название колонки"
						@input="input"
						placeholder="option"
				></v-text-field>
				<div class="block_input_param__value">
					<v-overflow-btn
							class="ml-5"
							:items="integrationStatus"
							label="Выберите колонку"
							height="40"
							item-value="id"
							item-text="name"
							v-model="data_local.amocrm.fields.status"
							hide-details
					></v-overflow-btn>
				</div>
			</div>

			<div class="block_input_param">
				<v-text-field
						class="block_input_param__options"
						:readonly="true"
						hide-details
						height="40"
						value="ТЕГИ"
						@input="input"
						placeholder="option"
				></v-text-field>
				<div class="block_input_param__value">
					<v-text-field
							:readonly="false"
							class="ml-5"
							hide-details
							height="40"
							v-model="data_local.amocrm.fields.tags"
							@input="input"
							placeholder="value"
					></v-text-field>
				</div>
			</div>

			<div class="block_input_param">
				<v-text-field
						class="block_input_param__options"
						:readonly="true"
						hide-details
						height="40"
						value="ДОБАВИТЬ ЗАПИСЬ"
						@input="input"
						placeholder="option"
				></v-text-field>
				<div class="block_input_param__value">
					<v-switch
							class="ml-5 mt-2"
							v-model="data_local.amocrm.fields.send_record"
							label="Да"
							hide-details
					></v-switch>
				</div>
			</div>

			
			<div class="block_input_param">
				<v-checkbox
						v-model="data_local.amocrm.fields.use_contact_name"
						label="Заполнять 'Название контакта'"
				></v-checkbox>
				<v-text-field
						class="ml-5"
						hide-details
						height="40"
						v-if="data_local.amocrm.fields.use_contact_name"
						v-model="data_local.amocrm.fields.contact_name"
						placeholder="Введите имя контакта"
				></v-text-field>
			</div>

			<div class="block_input_param">
				<v-checkbox
						v-model="data_local.amocrm.fields.use_utm_campaign"
						label="Заполнять 'UTM Campaign'"
				></v-checkbox>
				<v-text-field
						class="ml-5"
						hide-details
						height="40"
						v-if="data_local.amocrm.fields.use_utm_campaign"
						v-model="data_local.amocrm.fields.utm_campaign"
						placeholder="Введите UTM Campaign"
				></v-text-field>
			</div>

			<div class="block_input_param">
				<v-checkbox
						v-model="data_local.amocrm.fields.use_utm_medium"
						label="Заполнять 'UTM Medium'"
				></v-checkbox>
				<v-text-field
						class="ml-5"
						hide-details
						height="40"
            v-if="data_local.amocrm.fields.use_utm_medium"
						v-model="data_local.amocrm.fields.utm_medium"
						placeholder="Введите UTM Medium"
				></v-text-field>

			</div>

			<div class="block_input_param">
				<v-checkbox
						v-model="data_local.amocrm.fields.use_utm_content"
						label="Заполнять 'UTM Content'"
				></v-checkbox>
				<v-text-field
						class="ml-5"
						hide-details
						height="40"
						v-if="data_local.amocrm.fields.use_utm_content"
						v-model="data_local.amocrm.fields.utm_content"
						placeholder="Введите UTM Content (Название скрипта)"
				></v-text-field>
			</div>

			<div class="block_input_param">
				<v-checkbox
						v-model="data_local.amocrm.fields.use_utm_source"
						label="Заполнять 'UTM Source'"
				></v-checkbox>
				<v-text-field
						class="ml-5"
						hide-details
						height="40"
						v-if="data_local.amocrm.fields.use_utm_source"
						v-model="data_local.amocrm.fields.utm_source"
						placeholder="Введите UTM Source"
				></v-text-field>
			</div>
		</div>

		
		<div v-if="data_local.amocrm.type_event === arr_type[1].type">
			<div class="subtitle mt-3 mb-2">Параметры</div>
			<div class="block_input_param">
				<v-text-field
						class="block_input_param__options"
						:readonly="true"
						hide-details
						height="40"
						value="Название колонки"
						@input="input"
						placeholder="option"
				></v-text-field>
				<div class="block_input_param__value">
					<v-overflow-btn
							class="ml-5"
							:items="integrationStatus"
							label="Выберите колонку"
							height="40"
							item-value="id"
							item-text="name"
							v-model="data_local.amocrm.fields.status"
							hide-details
					></v-overflow-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "IntegrationAmoCRM",
	props: ['data', 'edit_callback'],
	data() {
		return {
			integrationListAMO: [],
			integrationStatus: [],
			data_local: {
				amocrm: {
					id_integartion: "",
					type_event: "",
					fields: {
						status: "",
						name: "",
						tags: "",
						send_record: false,
						contact_name: "",      
						utm_campaign: "",      
						utm_medium: "",        
						utm_content: "",       
						utm_source: "",        
						use_contact_name: false,
						use_utm_campaign: false,
						use_utm_medium: false,
						use_utm_content: false,
						use_utm_source: false
					}
				}
			},
			arr_type: [
				{name: 'Создать карточку', type: 'add_card'},
				{name: 'Переместить карточку', type: 'move_card'},
			],
		}
	},
	methods: {
		input() {
			this.edit_callback(this.data_local)
			return true
		},
		getAmoIntegrationsList() {
			this.$http.post("/integrations/get_amo_integration_list",
				{}, (res) => {
					if (res != false) {
						let arr = res.map((item) => {
							return {
								name: item.name,
								id: item.id
							}
						})
						this.integrationListAMO = arr;
						if (arr.length === 1) this.data_local.amocrm.id_integartion = arr[0]?.id
						this.getAmoStatusesList()
					} else {
						this.integrationListAMO = [];
					}

				})
		},
		getAmoStatusesList() {
			if (this.data_local.amocrm.id_integartion) {
				this.$http.post("/integrations/get_amo_statuses",
					{
						integration_id: this.data_local.amocrm.id_integartion
					}, (res) => {
						if (res != false) {
							this.integrationStatus = res;
						} else {
							this.integrationStatus = [];
						}

					})
			} else {
				this.integrationStatus = []
			}
		},
		clearAmocrmFields() {
			this.data_local.amocrm.fields = {
				status: "",
				name: "",
				tags: "",
				send_record: false,
				contact_name: "",
				utm_campaign: "",
				utm_medium: "",
				utm_content: "",
				utm_source: "",
				use_contact_name: false,
				use_utm_campaign: false,
				use_utm_medium: false,
				use_utm_content: false,
				use_utm_source: false
			}
			if (this.data_local.amocrm.type_event === this.arr_type[1].type && this.data_local.amocrm.id_integartion) {
				this.getAmoStatusesList()
			}
		}
	},
	watch: {
		data: function (val) {
			this.data_local = val
			this.getAmoIntegrationsList();
			this.integrationListAMO = []
		}
	},
	mounted() {
		this.data_local = this.data
		this.getAmoIntegrationsList();
	}
}
</script>

<style scoped>
.wraper {
    max-width: 600px;
    margin: 0 auto;
    width: 600px;
}

.add_pram {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 40px;
}

.add_pram__btn {
    color: #00809C;
    margin-left: 10px;
}

.delete_icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    border: 1px solid #FEE600;
    border-radius: 8px;
    display: flex;
}

.block_input_param {
    display: flex;
    margin-bottom: 15px;
}

/*.block_input_param__options {*/
/*    width: 35%;*/
/*}*/

.block_input_param__value {
    width: 61%;
    display: flex;
}

.subtitle {
    text-align: left;
    padding-left: 8px;
}

@media (max-width: 650px) {
    .wraper {
        width: 100%;
    }
}
</style>